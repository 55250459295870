import { getActiveFilters } from "./formatting";

//initial parmas must be an object
export const getParamsWithPagination = <T extends Record<string, unknown>>(
  initialParams: T,
  newPage?: number,
  newPerPage?: number
): Record<string, string | number | boolean> => {
  const params = getActiveFilters(initialParams);

  if (newPage !== undefined && newPerPage !== undefined) {
    if (newPerPage < 100 && newPage > 1) {
      params.per_page = newPerPage;
      params.page = newPage;
    }
    if (newPerPage < 100) {
      params.per_page = newPerPage;
    }
    if (newPage > 1) {
      params.page = newPage;
    }
  }

  return params;
};
